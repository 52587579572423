<template>
  <form>
    <v-row>
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit :form="journal" :accounts="accounts" ref="formEdit" v-if="!edit"></form-edit>
        <detail :id="id" :form="journal" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="journal.buffer">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="viewDetail">
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>Detail</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    {{ journal.buffer ? "mdi-check" : "mdi-content-save" }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ journal.buffer ? "Approved" : "Save" }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click.stop="deleteDialog">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row> -->
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="glTransaction.formNumber"
      title="GL Transaction"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete> -->
    <product-rr :dialog="dialogProduct" :items="products" @close="close"></product-rr>
    <product-mr :dialog="dialogMr" :mr="item" @close="close"></product-mr>
    <product-ho :dialog="dialogHo" :ho="item" @close="close"></product-ho>
  </form>
</template>

<script>
import Detail from "./DetailJournal.vue";
import FormEdit from "./FormJournal.vue";
// import DialogDelete from "@/components/DialogDelete";
import ProductRr from "../../purchases/purchase-down-payment/ProductRr.vue";
import ProductHo from "../../inventory/hand-over/ProductHo.vue";
import ProductMr from "../../inventory/material-request/ProductMr.vue";

export default {
  name: "journal-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    // "dialog-delete": DialogDelete,
    ProductRr,
    ProductHo,
    ProductMr,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    dialogProduct: false,
    dialogHo: false,
    dialogMr: false,
    accounts: [],
    products: [],
    journal: {},
    item: {},
  }),
  methods: {
    async getJournal() {
      await this.$store
        .dispatch("journal/getById", this.id)
        .then(response => (this.journal = response.data));
    },
    async getAcocunts() {
      await this.$store
        .dispatch("chartOfAccount/getAccounts", true)
        .then(response => (this.accounts = response.data));
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    deleteItem(id) {
      this.$store.dispatch("journal/delete", id);
      this.dialog = false;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = this.dialogProduct = this.dialogHo = this.dialogMr = false;
    },
    cancel() {
      this.getJournal();
      this.edit = true;
    },
    async viewDetail() {
      if (this.journal.title === "Inventory") {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("external/getProductByRrId", this.journal.relationId)
          .then(response => {
            this.products = response.data;
            this.$store.commit("SET_LOADER", false);
            this.dialogProduct = true;
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else if (this.journal.title === "Material Request") {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("inventory/getMaterialRequestDetail", this.journal.relationId)
          .then(response => {
            this.item = response.data;
            this.$store.commit("SET_LOADER", false);
            this.dialogMr = true;
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else if (this.journal.title === "Hand Over" || this.journal.title === "Return MR") {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("inventory/getHandOverDetail", this.journal.relationId)
          .then(response => {
            this.item = response.data;
            this.$store.commit("SET_LOADER", false);
            this.dialogHo = true;
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    getInnitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getAcocunts(), this.getJournal()])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInnitialData();
  },
};
</script>

<style></style>
