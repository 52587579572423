<template>
  <v-dialog v-model="dialog" max-width="1000" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Product HO</span>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="ho.items" disable-pagination hide-default-footer>
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.panelCodeExtItemPrice="{ item }">
            {{ formatPrice(item.snapshotPriceIdr) }}
          </template>
          <template v-slot:item.total="{ item }">
            {{ formatPrice(item.snapshotPriceIdr * item.qty) }}
          </template>
          <template v-slot:body.append="{}">
            <tr>
              <td colspan="7">TOTAL</td>
              <td style="border: 1px">
                {{ formatPrice(total) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close" class="mr-2">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "product-ho",
  props: {
    dialog: Boolean,
    ho: Object,
  },
  data() {
    return {
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Part#",
          value: "panelCodePartNumber",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Part Name",
          value: "partName",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Description",
          value: "partDesc",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Qty",
          value: "qty",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "U/M",
          value: "defaultUm",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "U/Price",
          value: "panelCodeExtItemPrice",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    total() {
      let total = 0;
      this.ho.items.forEach(x => {
        total += parseFloat(x.qty) * parseFloat(x.snapshotPriceIdr);
      });
      return total;
    },
  },
};
</script>

<style></style>
